import React, { useEffect, useState } from "react";
import {
   Button,
   Checkbox,
   EmptyState,
   Flex,
   FlexList,
   Input,
   Link,
   Modal,
   Spinner,
   Table,
   Tooltip,
   Typography,
} from "@procore/core-react";
import { Plus, Trash } from "@procore/core-icons";
import type { OauthAppsPropTypes } from "@/react/prop-types";
import type { OauthAppData } from "@/models/oauth-app";

const OauthAppsTable = (props: OauthAppsPropTypes) => {
   const DEFAULT_MODAL_CONTENT = { name: "" };
   const { getData, updateData, createData, deleteData } = props;
   const [isLoading, setLoading] = useState<boolean>(true);
   const [oauthApps, setOauthApps] = useState<OauthAppData[] | undefined>([]);
   const [isModalShowing, setModalShowing] = useState<boolean>(false);
   const [modalType, setModalType] = useState<"create" | "info">("create");
   const [modalContent, setModalContent] = useState<Partial<OauthAppData>>(DEFAULT_MODAL_CONTENT);
   const [errorRow, setErrorRow] = useState<string>("");

   useEffect(() => {
      const getOauthApps = async () => {
         const oauthApps = await getData();
         setOauthApps(oauthApps);
         setLoading(false);
      };

      getOauthApps();
   }, []);

   const onClose = () => {
      setModalShowing(false);
      setTimeout(() => {
         setModalContent(DEFAULT_MODAL_CONTENT);
         setModalType("create");
      }, 200);
   };

   return isLoading ? (
      <Spinner loading={true}>
         <Flex wrap="wrap" alignContent="center" />
      </Spinner>
   ) : (
      <>
         <Modal open={isModalShowing} onClose={onClose}>
            <Modal.Header onClose={onClose}>Create New Oauth App</Modal.Header>
            <Modal.Body>
               {modalType === "create" ? (
                  <Flex justifyContent="center" alignItems="center">
                     <Typography className="padding-right" intent="h3">
                        Name:
                     </Typography>
                     <Input
                        value={modalContent?.name}
                        onChange={(e) => setModalContent({ name: e.target.value })}
                     />
                  </Flex>
               ) : (
                  <FlexList direction="column">
                     <Typography>
                        Your oauth app: <b>{modalContent?.name}</b> has been created successfully.
                     </Typography>
                     <Typography>
                        Your client secret is: <b>{modalContent?.client_secret}</b>
                     </Typography>
                     <Typography>
                        Please keep this somewhere safe. You will not be able to see it again unless
                        it is reset.
                     </Typography>
                  </FlexList>
               )}
            </Modal.Body>
            <Modal.Footer>
               {modalType === "create" && (
                  <Modal.FooterButtons>
                     <Button
                        variant="secondary"
                        onClick={() => {
                           setModalShowing(false);
                           setModalContent(DEFAULT_MODAL_CONTENT);
                        }}
                     >
                        Cancel
                     </Button>
                     <Button
                        variant="primary"
                        disabled={!modalContent?.name}
                        onClick={async () => {
                           const nextIndex = oauthApps;
                           const created = (await createData(modalContent)) || ({} as OauthAppData);
                           nextIndex?.push(created);
                           setOauthApps(nextIndex);
                           setModalType("info");
                           setModalContent(created);
                        }}
                     >
                        Create
                     </Button>
                  </Modal.FooterButtons>
               )}
            </Modal.Footer>
         </Modal>
         {oauthApps && oauthApps.length > 0 ? (
            <>
               <Flex className="base-url" alignItems="center">
                  <Typography intent="h3" weight="bold" className="padding-right">
                     Your API base url is:{" "}
                  </Typography>
                  <Typography>
                     {(window as any).config.LC_DOMAIN.replace("https://", "https://api-")}
                  </Typography>
               </Flex>
               <Table.Container>
                  <Table>
                     <Table.Header className="header-row-height">
                        <Table.HeaderRow>
                           <Table.HeaderCell>
                              <Flex justifyContent="center">
                                 <Tooltip overlay="Create a New Oauth App" trigger="hover">
                                    <Button
                                       icon={<Plus />}
                                       onClick={() => {
                                          setModalContent(DEFAULT_MODAL_CONTENT);
                                          setModalShowing(true);
                                       }}
                                    />
                                 </Tooltip>
                              </Flex>
                           </Table.HeaderCell>
                           <Table.HeaderCell className="header-cell">Name</Table.HeaderCell>
                           <Table.HeaderCell className="header-cell">Client ID</Table.HeaderCell>
                           <Table.HeaderCell className="header-cell">
                              Client Secret
                           </Table.HeaderCell>
                           <Table.HeaderCell className="header-cell">Active</Table.HeaderCell>
                        </Table.HeaderRow>
                     </Table.Header>

                     <Table.Body>
                        {oauthApps?.map((oauthApp) => {
                           return (
                              <Table.BodyRow key={oauthApp.id}>
                                 <Table.BodyCell>
                                    <Flex wrap="wrap" justifyContent="center">
                                       <Trash
                                          className="icon-cursor"
                                          onClick={async () => {
                                             const deleted = await deleteData(oauthApp.id);
                                             setOauthApps(
                                                oauthApps?.filter((o) => o?.id !== deleted),
                                             );
                                          }}
                                       />
                                    </Flex>
                                 </Table.BodyCell>
                                 <Table.BodyCell>
                                    <div className="input-max-width">
                                       <Table.InputCell
                                          size="block"
                                          defaultValue={oauthApp.name}
                                          errorMessage={
                                             errorRow == oauthApp.id ? "Name cannot be blank" : ""
                                          }
                                          onBlur={(e) => {
                                             if (!e.target.value) {
                                                setErrorRow(oauthApp.id);
                                             } else {
                                                setErrorRow("");
                                                updateData({
                                                   id: oauthApp.id,
                                                   name: e.target.value,
                                                });
                                             }
                                          }}
                                       >
                                          {oauthApp.name}
                                       </Table.InputCell>
                                    </div>
                                 </Table.BodyCell>
                                 <Table.BodyCell>
                                    <Table.TextCell>{oauthApp.client_id}</Table.TextCell>
                                 </Table.BodyCell>
                                 <Table.BodyCell>
                                    <Table.TextCell>{oauthApp.client_secret}</Table.TextCell>
                                 </Table.BodyCell>
                                 <Table.BodyCell>
                                    <Flex justifyContent="center">
                                       <Checkbox
                                          className="checkbox-position"
                                          checked={oauthApp.active}
                                          onChange={async () => {
                                             const update = await updateData({
                                                id: oauthApp.id,
                                                active: !oauthApp.active,
                                             });
                                             setOauthApps(
                                                oauthApps?.map((o) =>
                                                   o?.id === update?.id ? update : o,
                                                ),
                                             );
                                          }}
                                       />
                                    </Flex>
                                 </Table.BodyCell>
                              </Table.BodyRow>
                           );
                        })}
                     </Table.Body>
                  </Table>
               </Table.Container>
            </>
         ) : (
            <EmptyState>
               <EmptyState.NoItems />
               <EmptyState.Title>No Items</EmptyState.Title>
               <EmptyState.Description>
                  There are no Oauth Apps for this company. To get started, click Create below.
               </EmptyState.Description>
               <EmptyState.Actions>
                  <Button
                     onClick={() => {
                        setModalContent(DEFAULT_MODAL_CONTENT);
                        setModalShowing(true);
                     }}
                  >
                     Create
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         )}
         <Flex justifyContent="center" alignItems="center" className="support-link">
            <Link
               className="no-underline"
               target="_blank"
               href="https://wfp-quickconnect.readme.io/docs/auth#oauth-20-authentication"
            >
               Learn More Here
            </Link>
         </Flex>
      </>
   );
};

export default OauthAppsTable;
